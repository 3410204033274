<template>
  <button :class="buttonStyle">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['buttonStyle'],
};
</script>

<style>
button {
  width: 100%;
  background-color: var(--blue);
  font-size: 14px;
  font-weight: 600;
  color: white;
  border: 2px solid var(--blue);
  padding: 12px 12px;
  border-radius: var(--radiusSmall);
  opacity: 1;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 0px;
}

button:hover {
  opacity: 0.7;
}

.danger{
  color: var(--red);
  background-color: white;
  border-color: var(--red);
  margin: 0 20px ;
}

.danger:hover{
  background-color: var(--red);
  color: white;
  opacity: 1;
}

.hollow{
  background-color: transparent;
  border-radius: 2px solid white;
  border-color: white;
}

.hollow:hover{
  background-color: white;
  color: black;
  opacity: 1;
}
</style>