import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faCalendarDays,
  faGear,
  faMagnifyingGlass,
  faSignOutAlt,
  faUser,
  faBolt,
  faCarBattery,
  faPlus,
  faMinus,
  faChevronCircleLeft,
  faChevronCircleRight,
  faBars,
  faTimes,
  faCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import { MotionPlugin } from "@vueuse/motion";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);

library.add(
  faChevronDown,
  faGoogle,
  faCalendarDays,
  faGear,
  faMagnifyingGlass,
  faSignOutAlt,
  faUser,
  faBolt,
  faCarBattery,
  faPlus,
  faMinus,
  faChevronCircleLeft,
  faChevronCircleRight,
  faBars,
  faTimes,
  faCircle,
  faExclamationTriangle,
  faInfoCircle
);
app.component("fa", FontAwesomeIcon);

app.use(MotionPlugin);
app.use(Vue3TouchEvents, {
  swipeTolerance: 199,
});

app.use(store);
app.use(router);
app.mount("#app");
