<template>
  <div id="reservationDeleteWrapper">
    <div id="reservationDeleteContent">
      <h3>Reservering verwijderen</h3>
      <div id="removeDateDiv">
        <p>Weet je zeker dat je de reservering op:</p>
        <h3 id="removeDate">{{ getStartDate }}</h3>
        <p>wilt verwijderen?</p>
      </div>
      <div id="buttonDiv">
        <the-base-button class="button cancel" @click="$emit('close')"
          >Sluiten</the-base-button
        >
        <the-base-button
          class="button accept"
          @click="removeReservation(deleteEvent.eventId)"
          >Verwijderen</the-base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { projectDb } from "../../firebaseConfig";
import TheBaseButton from "../UI/TheBaseButton.vue";

export default {
  components: {
    TheBaseButton,
  },
  emits: ["close" , "reload"],
  props: ["deleteEvent"],
  methods: {
    removeReservation(eventId) {
      var events = [];
      projectDb
        .collection("calendar-data")
        .doc("reservations")
        .get()
        .then((doc) => {
          if (doc.exists) {
            var docData = doc.data();
            events = docData.events;
            for (let i = 0; i < events.length; i++) {
              if (events[i].eventId == eventId && events[i].class == this.deleteEvent.class) {
                events.splice(i, 1);
              }
            }
          }

          projectDb
            .collection("calendar-data")
            .doc("reservations")
            .set({
              events,
            })
            .then(this.$emit("reload"))
            .then(this.$emit("close"));
        });
    },
  },
  computed: {
    getStartDate() {
      var date = this.deleteEvent.end;

      var month = date.getMonth() + 1;
      var day = date.getDate();
      var year = date.getFullYear();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return day + "-" + month + "-" + year;
    },
  },
};
</script>

<style scoped>
#reservationDeleteWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#reservationDeleteContent {
  position: relative;
  background-color: white;
  border-radius: var(--radius);
  padding: 40px 40px 40px 40px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

#buttonDiv {
  display: flex;
  gap: 5px;
}

.cancel {
  background-color: lightGrey;
  border: lightGrey 2px solid;
  color: black;
  box-shadow: var(--shadow);
}

.accept {
  background-color: var(--red);
  border-color: var(--red);
  box-shadow: var(--shadow);
}

#removeDateDiv {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#removeDate {
  padding: 20px 40px;
  background-color: var(--lightBlue);
  border-radius: var(--radiusSmall);
  max-width: 150px;
  margin: 20px 0;
}
</style>