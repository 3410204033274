<template>
  <div id="homeWrapper">
    <the-navbar
      :activeView="activeViewMobile"
      @openSidebar="openSidebar = !openSidebar"
    ></the-navbar>
    <the-calendar-comp
      v-if="$route.params.nav == 'home'"
      @activeView="setActiveView"
      :openSidebar="openSidebar"
    ></the-calendar-comp>
    <the-settings-comp
      v-if="$route.params.nav == 'settings'"
    ></the-settings-comp>
    <the-mobile-messages
      v-if="$route.params.nav == 'messagesMobile'"
    ></the-mobile-messages>
  </div>
</template>

<script>
import TheCalendarComp from "../components/TheCalendarComp.vue";
import TheNavbar from "../components/TheNavbar.vue";
import TheSettingsComp from "../components/TheSettingsComp.vue";
import TheMobileMessages from "../components/TheMobileMessages.vue";
import { projectAuth } from "../firebaseConfig";

export default {
  components: {
    TheNavbar,
    TheCalendarComp,
    TheSettingsComp,
    TheMobileMessages,
  },
  name: "Home",
  data() {
    return {
      activeViewMobile: new Date(),
      openSidebar: false,
    };
  },

  methods: {
    logOut() {
      projectAuth.signOut();
    },
    setActiveView(payload) {
      this.activeViewMobile = payload;
    },
  },
};
</script>

<style scoped>
#homeWrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow: hidden; */
}
</style>
