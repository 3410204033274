<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --blue: #559fe4;
  --lightBlue: #f1f7fd;
  --black: #000000;
  --grey: #899197;
  --green: #65d663;
  --lightGreen: #65d66354;
  --red: #dc5e5e;
  --yellow: ##ffec8b;
  --line: #e0e0e0;

  --radius: 30px;
  --radiusSmall: 12px;

  --shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

.wall {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  /* background-color: magenta; */
  z-index: -1;
}

h1 {
  font-weight: 800;
  font-size: 42px;
}

h2 {
  font-size: 32px;
  font-weight: 800;
  margin: 0 0 20px 0;
}
h3 {
  font-size: 24px;
  font-weight: 600;
}

h4 {
  font-weight: 18px;
  font-weight: 600;
}

p {
  font-size: 14px;
}

b {
  font-weight: 700;
}
.subtitle,
label {
  font-size: 12px;
  font-weight: 400;
}

input[type="text"],
input[type="password"],
textarea {
  border-radius: var(--radiusSmall);
  padding: 18px 18px;
  border: none;
  background-color: var(--lightBlue);
  margin: 20px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
