import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TheLoginView from "../views/TheLoginView.vue"

import { projectAuth } from "../firebaseConfig.js"

const routes = [{
        path: '/:nav?/:subnav?',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: TheLoginView,
        meta: {
            requiresAuth: false
        }
    },
    { path: '/', redirect: { path: '/home' } },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async(to, next) => {
    const reqAuth = to.matched.some(record => record.meta.requiresAuth);

    /* checking if route requires auth, if so check if user is present, otherwise route to /login */

    if (reqAuth) {
        projectAuth.onAuthStateChanged((user) => {
            if (user) { next } else { router.push("/login") }
        });
    }

    /* if a user is logged in then route /login to /home */

    if (to.fullPath == "/login") {
        projectAuth.onAuthStateChanged((user) => {
            if (user) { router.push("/home") } else { next }
        });
    } else { next }

});

export default router