<template>
  <div id="settingsWrapper">
    <div id="settingsNav">
      <div id="searchDiv">
        <input type="text" placeholder="Search..." />
        <fa class="ico searchIco" icon="magnifying-glass"></fa>
      </div>
      <!-- <div
        class="navItem"
        :class="{ active: $route.params.subnav == 'general' }"
        @click="$router.push('/settings/general')"
      >
        <h4><fa class="ico" icon="gear"></fa>Algemene instellingen</h4>
      </div> -->
      <div
        class="navItem"
        :class="{ active: $route.params.subnav == 'account' }"
        @click="$router.push('/settings/account')"
      >
        <h4><fa class="ico" icon="user"></fa>Account details</h4>
      </div>
      <div class="navItem logOut" @click="logOut()">
        <h4><fa class="ico" icon="sign-out-alt"></fa>Uitloggen</h4>
      </div>
    </div>
    <the-general-settings v-if="$route.params.subnav == 'general'"></the-general-settings>
    <the-account-settings v-if="$route.params.subnav == 'account'"></the-account-settings>
  </div>
</template>

<script>
import { projectAuth } from "../firebaseConfig.js";
import TheGeneralSettings from "./TheGeneralSettings.vue";
import TheAccountSettings from "./TheAccountSettings.vue";


export default {
  components: {
    TheGeneralSettings,
    TheAccountSettings,
  },
  data() {
    return {
      openTab: "general",
    };
  },
  methods: {
    logOut() {
      projectAuth.signOut().then(this.$router.push('/login'));
    },
  },
};
</script>

<style scoped>
#settingsWrapper {
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-columns: 450px 1fr;
}

#settingsNav {
  border-right: 1px solid var(--line);
  box-sizing: border-box;
  padding: 60px;
}

#searchDiv {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 60px 0;
}

input {
  margin: 0px;
}

.searchIco {
  position: absolute;
  right: 20px;
}

.ico {
  margin: 0 15px 0 0;
}

.navItem {
  margin: 80px 0;
  cursor: pointer;
  color: var(--grey);
}

.active {
  color: var(--blue);
}

.logOut {
  color: var(--red);
}
</style>