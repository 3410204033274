<template>
  <div class="cardWrapper">
    <div id="cardWall"></div>
    <h3>Hulp nodig?</h3>
    <p>
      Eerste keer hier? klik hieronder voor een uitgebreide tour van alle
      features.
    </p>
    <the-base-button style="width: 150px" buttonStyle="hollow"
      >Starten ></the-base-button
    >
  </div>
</template>

<script>
import TheBaseButton from "./TheBaseButton.vue";

export default {
  components: { TheBaseButton },
};
</script>

<style scoped>
.cardWrapper {
  background: rgb(37, 160, 35) !important;
  background: linear-gradient(
    45deg,
    rgba(37, 160, 35, 1) 0%,
    rgba(125, 221, 123, 1) 100%
  ) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

p {
  max-width: 220px;
  line-height: 21px;
}

#cardWall {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/illustrations/woman.png");
  background-position: right;
  background-size: cover;
  top: 0px;
  right: 0px;
  z-index: -1;
}
</style>