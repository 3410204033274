<template>
  <div
    class="cardWrapper"
    :class="{
      important: messageData.type == 'alert',
      info: messageData.type == 'info',
    }"
    v-if="messageData"
  >
    <div id="cardWall"></div>
    <div id="cardTitle">
      <h3>{{ messageData.title }}</h3>
      <fa
        class="icon"
        icon="exclamation-triangle"
        v-if="messageData.type == 'alert'"
      ></fa>
      <fa
        class="icon"
        icon="info-circle"
        v-if="messageData.type == 'info'"
      ></fa>
    </div>
    <p>
      {{ messageData.message }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["messageData"],
};
</script>

<style scoped>
.cardWrapper {
  background: rgb(37, 160, 35);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

#cardWall {
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: right;
  background-size: cover;
  top: 0px;
  right: 0px;
  z-index: -1;
}

#cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.icon {
  font-size: 21px;
}

.important {
  background: var(--red) !important;
}

.info {
  background-color: var(--blue) !important;
}

.mediumAlert {
  background-color: black;
}
</style>