<template>
  <div id="navUserWrapper">
    <p v-if="user" id="username">{{ user.displayName }}</p>
    <div
      id="userCircle"
      v-if="user"
      :style="{ backgroundImage: 'url(' + user.photoURL + ')' }"
    ></div>
  </div>
</template>

<script>
import { projectAuth, projectDb } from "../../firebaseConfig.js";

export default {
  data() {
    return {
      user: null,
    };
  },
  methods: {
    /* Use this to modify your users appearance */

    modifyUser() {
      projectAuth.currentUser.updateProfile({
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/carshare-9117d.appspot.com/o/helianthus-yellow-flower-pixabay_11863.webp?alt=media&token=26fbe8d9-a293-415d-a1b6-0b1fe2a3abc4",
      });
    },

    /* Checks or creates the entry for the user in the Firebase database */

    checkForDb(user) {
      const usersRef = projectDb.collection("users").doc(user.uid);
      usersRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          usersRef.onSnapshot(() => {
            this.$router.push("/");
          });
        } else {
          projectDb
            .collection("users")
            .doc(user.uid)
            .set({
              displayName: user.displayName,
              uid: user.uid,
              settings: [true, true, false],
            });
        }
      });
    },
  },

  /* Loads the user data into the component */

  async mounted() {
    await projectAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.checkForDb(user);
      }
    });
    return null;
  },
};
</script>

<style scoped>
#navUserWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
}

#userCircle {
  border-radius: 1000px;
  height: 50px;
  width: 50px;
  background-color: aquamarine;
  margin: 0 20px 0 20px;
  background-position: center;
  background-size: cover;
  box-shadow: var(--shadow);
}

.ico {
  margin: 0 0 0 10px;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
#navUserWrapper {
  text-align: right;
}

  #userCircle {
    aspect-ratio: 1 / 1;
    height: 46px;
    width: 46px;
    margin: 0 20px 0 0;
  }

  #username{
    display: none;
  }
}
</style>  