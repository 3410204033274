import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCI8ZzPRN6UvmGvHVdPC296_nJeJ7ibtWg",
    authDomain: "carshare-9117d.firebaseapp.com",
    projectId: "carshare-9117d",
    storageBucket: "carshare-9117d.appspot.com",
    messagingSenderId: "711986800645",
    appId: "1:711986800645:web:07a5e191cd1ff0633d946c"
};

firebase.initializeApp(firebaseConfig);

const projectAuth = firebase.auth();
const projectDb = firebase.firestore();
const projectFb = firebase

export { projectAuth, projectDb, projectFb };