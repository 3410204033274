<template>
  <div id="calBarWrapper">
    <h1>Agenda</h1>
    <div id="buttonDiv">
      <p id="nowButton" @click="$emit('now')" v-if="!chechForCurrentMonth">
        Naar Vandaag
      </p>

      <fa class="ico" icon="chevron-circle-left" @click="$emit('back')"></fa>
      <p>{{ currentDate }}</p>
      <fa class="ico" icon="chevron-circle-right" @click="$emit('next')"></fa>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["back", "next", "now"],
  props: ["date"],
  data() {
    return {
      monthSelector: 0,
    };
  },

  computed: {
    currentDate() {
      const monthNames = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];

      let year = this.date.getFullYear();
      let month = this.date.getMonth();

      return monthNames[month] + " " + year;
    },
    chechForCurrentMonth() {
      const monthNames = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];
      let newDate = new Date();
      let year = newDate.getFullYear();
      let month = newDate.getMonth();

      let checkDate = monthNames[month] + " " + year;

      if (checkDate === this.currentDate) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
#calBarWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 40px 0 40px;
}

.ico {
  cursor: pointer;
}

#buttonDiv {
  font-size: 21px;
  display: flex;
  align-items: center;
  gap: 15px;
}

#nowButton {
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
  padding: 10px 12px;
  border: 1px solid var(--blue);
  border-radius: var(--radiusSmall);
  margin: 0 15px 0 0;
  transition: all 0.2s ease-in-out;
}

#nowButton:hover {
  background-color: var(--blue);
  color: white;
}

h1 {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

p {
  min-width: 120px;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

@media screen and (min-width: 500px) and (max-width: 1600px) {
  #calBarWrapper {
    padding: 15px 30px 0 30px;
    margin: 0;
  }

  h1 {
    font-size: 42px;
    margin: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #calBarWrapper {
    display: none;
  }
}
</style>