<template>
  <div id="reservationWrapper">
    <div id="reservationContent">
      <div id="img" v-motion-slide-left :delay="20"></div>

      <h3>Nieuwe reservering</h3>
      <p>voor Renault ZOE Z40</p>
      <div id="counterWrapper">
        <div class="countButton minus" @click="counter--">
          <fa icon="minus"></fa>
        </div>
        <div id="counterDiv">
          <h1>{{ counter }}</h1>
          <p v-if="counter > 1">dagen</p>
          <p v-else>dag</p>
        </div>
        <div class="countButton plus" @click="counter++">
          <fa icon="plus"></fa>
        </div>
      </div>
      <p id="dates">
        Reservering van: <b>{{ getFullDate }} </b> tot <b>{{ getEndDate }}</b>
      </p>

      <div id="buttonDiv">
        <the-base-button class="button cancel" @click="$emit('close')"
          >Annuleren</the-base-button
        >
        <the-base-button class="button accept" @click="sumbitEvent()"
          >Inplannen</the-base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { projectDb, projectFb } from "../../firebaseConfig";
import TheBaseButton from "./TheBaseButton.vue";
import emailjs from "@emailjs/browser";

export default {
  components: { TheBaseButton },
  props: ["startDate", "user"],
  emits: ["close", "reload"],
  data() {
    return {
      counter: 1,
    };
  },
  methods: {
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    /* Adds the new event to the Firebase database and calls email method, reloads calendar depedencies and closes modal */

    sumbitEvent() {
      const eventRef = projectDb
        .collection("calendar-data")
        .doc("reservations");
      const event = {
        class: this.getReservationClass,
        start: this.getReservationStart,
        end: this.getReservationEnd,
        title: this.user.displayName,
        eventId: this.getReservationStart,
      };

      eventRef
        .update({
          events: projectFb.firestore.FieldValue.arrayUnion(event),
        })
        .then(this.sendReserationEmail())
        .then(this.$emit("reload"))
        .then(this.$emit("close"));
    },

    /* Sends email notification of the new reservation to al the users in de firestore database  */

    async sendReserationEmail() {
      var users = await projectDb.collection("users");

      users.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().email !== this.user.email) {
            let templateParams = {
              to_name: doc.data().displayName,
              reserver_name: this.user.displayName,
              reseration_start: this.getFullDate,
              reservation_end: this.getEndDate,
              to: doc.data().email,
            };

            emailjs.send(
              "renaultzoesharegmail",
              "template_7cxpptd",
              templateParams,
              "hd-Le7DHAMUa_ieUL",
              {}
            );
          }
        });
      });
    },
  },
  computed: {
    getFullDate() {
      var date = this.startDate;

      var month = date.getMonth() + 1;
      var day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return day + "-" + month;
    },
    getEndDate() {
      var rawDate = this.addDays(this.startDate, this.counter - 1);

      var month = rawDate.getMonth() + 1;
      var day = rawDate.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      return day + "-" + month;
    },
    getReservationStart() {
      var date = this.startDate;

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },

    getReservationEnd() {
      var rawDate = this.addDays(this.startDate, this.counter - 1);

      var year = rawDate.getFullYear();
      var month = rawDate.getMonth() + 1;
      var day = rawDate.getDate();

      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },
    getReservationClass() {
      if (this.user.uid == "0tjgEBE5cLgM8Av4NG6hJbnW1DC3") {
        return "olivierMesselink";
      }
      if (this.user.uid == "M9qcllcWbQab265TX6Jle4jazHo2") {
        return "resiMesselink";
      }
      if (this.user.uid == "tcxEBYkO7qZwAWwG6f4Gc1rkwuH2") {
        return "liaKooremans";
      }
      return null;
    },
  },
  watch: {
    counter() {
      if (this.counter < 1) {
        this.counter = 1;
      }
      if (this.counter > 30) {
        this.counter = 30;
      }
    },
  },
};
</script>

<style scoped>
#reservationWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#img {
  position: absolute;
  top: -108px;
  left: 0px;
  aspect-ratio: 2.5 / 1;
  width: 100%;
  background-image: url("../../assets/illustrations/zoePurpleCentered.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

#reservationContent {
  position: relative;
  background-color: white;
  border-radius: var(--radius);
  padding: 80px 40px 40px 40px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

h3 {
  margin: 0 0 10px 0;
}

h1 {
  margin: 0 0 8px 0;
}

#counterWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}

.countButton {
  background-color: var(--blue);
  padding: 10px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radiusSmall);
  color: white;
  font-size: 28px;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: var(--shadow);
}

.countButton:hover {
  transform: scale(1.05);
}

#counterDiv {
  padding: 10px;
  height: 130px;
  background-color: var(--lightBlue);
  margin: 0 30px;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
}

#dates {
  margin: 40px 0;
}

#buttonDiv {
  display: flex;
}

.cancel {
  border-radius: var(--radiusSmall) 0 0 var(--radiusSmall) !important;
  background-color: var(--red);
  border-color: var(--red);
  box-shadow: var(--shadow);
}

.accept {
  border-radius: 0 var(--radiusSmall) var(--radiusSmall) 0 !important;
  background-color: var(--green);
  border-color: var(--green);
  box-shadow: var(--shadow);
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #reservationContent {
    transform: scale(0.9);
  }
}
</style>