<template>
  <div id="navWrapper">
    <transition name="slide">
      <the-mobile-menu
        v-if="mobileMenuOpen"
        @closeMenu="mobileMenuOpen = false"
      ></the-mobile-menu>
    </transition>
    <div class="navRow mediumNav">
      <div class="counterWrapper">
        <fa
          @click="openSidebar = !openSidebar"
          class="ico"
          icon="info-circle"
          :class="{ openedSidebar: openSidebar }"
        ></fa>
        <p class="counter" v-if="messageCounter">{{ messageCounter }}</p>
      </div>
    </div>
    <div class="navRow">
      <div id="navDiv">
        <div class="counterWrapper">
          <div class="mobileMenuIco" @click="mobileMenuOpen = true">
            <fa icon="bars"></fa>
            <p class="counter mobileCounter" v-if="messageCounter">
              {{ messageCounter }}
            </p>
          </div>
        </div>
        <div
          class="navItem"
          :class="{ active: $route.path == '/home' }"
          @click="$router.push('/home')"
        >
          <fa class="ico" icon="calendar-days"></fa>
          <h4>Agenda</h4>
        </div>
        <div
          class="navItem"
          :class="{ active: $route.params.nav == 'settings' }"
          @click="$router.push('/settings/account')"
        >
          <fa class="ico" icon="gear"></fa>
          <h4>Instellingen</h4>
        </div>
      </div>
    </div>
    <div class="navRow mobile" :class="{ currentDate: chechForCurrentMonth }">
      {{ currentDate
      }}<fa class="smallDot" icon="circle" v-if="chechForCurrentMonth"></fa>
    </div>
    <div class="navRow">
      <the-nav-user-comp
        @click="$router.push('/settings/account')"
      ></the-nav-user-comp>
    </div>
  </div>
</template>

<script>
import TheNavUserComp from "./UI/TheNavUserComp.vue";
import TheMobileMenu from "./UI/TheMobileMenu.vue";
import { projectDb } from "../firebaseConfig.js";

export default {
  components: { TheNavUserComp, TheMobileMenu },
  props: ["activeView"],
  emits: ["openSidebar"],
  data() {
    return {
      mobileMenuOpen: false,
      openSidebar: false,
      messageCounter: 0,
    };
  },
  computed: {
    currentDate() {
      const monthNames = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];

      let year = this.activeView.getFullYear();
      let month = this.activeView.getMonth();

      return monthNames[month] + " " + year;
    },

    chechForCurrentMonth() {
      const monthNames = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];
      let newDate = new Date();
      let year = newDate.getFullYear();
      let month = newDate.getMonth();

      let checkDate = monthNames[month] + " " + year;

      if (checkDate === this.currentDate) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async getMessagesLength() {
      var messages = await projectDb.collection("messages");

      messages.get().then((querySnapshot) => {
        querySnapshot.forEach(() => {
          this.messageCounter++;
        });
      });
    },
  },
  watch: {
    openSidebar() {
      this.$emit("openSidebar");
    },
  },
  mounted() {
    this.getMessagesLength();
  },
};
</script>

<style scoped>
#navWrapper {
  border-bottom: 1px solid var(--line);
  width: 100%;
  height: 100px;
  display: flex;
  gap: 15px;
  padding: 0 60px;
  box-sizing: border-box;
}

.navRow {
  height: 100%;
  width: 100%;
}

#navDiv {
  display: flex;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navItem,
.mobileMenuIco {
  display: flex;
  align-items: center;
  margin: 0 20px;
  font-size: 21px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.mobileMenuIco,
.mobile {
  display: none;
}

.navItem:hover {
  transform: scale(1.05);
}

.ico {
  margin: 0 10px 0 0;
  cursor: pointer;
}

.active {
  font-weight: 900;
  color: var(--blue);
}

.mediumNav {
  opacity: 0;
}

.counterWrapper {
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.counter {
  position: absolute;
  top: -8px;
  right: 2px;
  background-color: var(--red);
  padding: 2px;
  width: 13px;
  height: 13px;
  border-radius: 10000px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
  font-weight: bold;
}

.mobileCounter {
  top: 0px;
  right: 15px;
}

.openedSidebar {
  color: var(--blue);
}

@media screen and (min-width: 1280px) and (max-width: 1600px) {
  .mediumNav {
    display: flex;
    opacity: 1;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
}

@media screen and (min-width: 500px) and (max-width: 1280px) {
  #navWrapper {
    display: flex;
    align-items: space-between;
    padding: 0px;
  }

  #navDiv {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 15px;
  }

  .mediumNav {
    display: flex;
    opacity: 1;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #navWrapper {
    padding: 0;
    display: flex;
    gap: 0px;
  }

  .navItem {
    font-size: 24px;
    margin: 0 10px;
    display: none;
  }

  #navDiv:first-child {
    justify-content: flex-start;
  }

  h4 {
    display: none;
  }

  .mediumNav {
    display: none;
  }
  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .mobileMenuIco {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    padding: 8px;
    box-sizing: border-box;
    background-color: var(--blue);
    border-radius: 10000px;
    color: white;
    font-size: 18px;
  }

  .smallDot {
    font-size: 8px;
    margin: 0 0 0 8px;
  }

  .currentDate {
    color: var(--blue);
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
  }

  .slide-leave-active,
  .slide-enter-active {
    transition: all 0.2s ease-in-out;
  }

  .slide-enter-to,
  .slide-leave-from {
    opacity: 1;
    transform: translateX(0vw);
  }
}
</style>