<template>
  <div id="messageWrapper">
    <div id="messageContent">
      <h3>Nieuw bericht</h3>
      <input
        type="text"
        name="title"
        v-model="newMessage.title"
        placeholder="Bericht titel"
      />
      <textarea
        v-model="newMessage.message"
        placeholder="Type hier uw bericht"
        @keyup.enter="publishMessage()"
      ></textarea>
      <the-base-button @click="publishMessage()">Opslaan</the-base-button>
      <the-base-button
        @click="$emit('closeModal')"
        style="
          background-color: #ededed;
          color: var(--red);
          margin-top: 12px;
          border-color: transparent;
        "
        >Anuleren</the-base-button
      >
    </div>
  </div>
</template>

<script>
import TheBaseButton from "./TheBaseButton.vue";
import { projectDb } from "../../firebaseConfig.js";

export default {
  components: { TheBaseButton },
  emits: ["reloadMessages", ""],
  data() {
    return {
      newMessage: {
        title: "",
        message: "",
        type: "alert",
      },
    };
  },
  methods: {
    async publishMessage() {
      const messageDbRef = await projectDb.collection("messages");
      messageDbRef
        .add({
          title: this.newMessage.title,
          message: this.newMessage.message,
          type: "info",
        })
        .then(this.$emit("reloadMessages"));
    },
  },
};
</script>

<style scoped>
#messageWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 0 10px;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#messageContent {
  position: relative;
  background-color: white;
  border-radius: var(--radius);
  padding: 30px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  max-width: 400px;
}

input {
  border: 2px solid transparent;
}

textarea {
  margin: 18px 0;
  border: 2px solid transparent;
  min-height: 100px;
  max-height: 500px;
  resize: vertical;
}

h5 {
  margin: 18px 0 0 0;
}
</style>