<template>
  <div class="cardWrapper">
    <div id="cardWall"></div>
    <div id="carName">
      <h3>RENAULT</h3>
      <p>ZOE ZE40 (R110)</p>
    </div>

    <the-charge-bar></the-charge-bar>
  </div>
</template>

<script>
import TheChargeBar from "./TheChargeBar.vue";

export default {
  components: { TheChargeBar },
};
</script>

<style scoped>
.cardWrapper {
  background: rgb(15, 81, 154) !important;
  background: linear-gradient(
    -45deg,
    #2A2165 0%,
    #8C6AA9 100%
  ) !important;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 40px 30px 40px !important;
}

#cardWall {
  overflow: visible !important;
  position: absolute;
  height: 90%;
  width: 90%;
  background-image: url("../../assets/illustrations/zoePurple.png");

  background-size: cover;
  top: -10px;
  left: -30px;
  z-index: 0;
}

h3 {
  margin: 0 0 5px 0;
  font-weight: 800;
}

</style>