<template>
  <div id="chargeWrapper">
    <div id="rangeDiv">
      <p>Actieradius:</p>
      <h3>{{ range }}</h3>
      <p>Km</p>
    </div>
    <div id="barLine">
      <div
        id="barWrapper"
        :style="{ width: charge + '%' }"
        :class="{ empty: isEmpty }"
      >

        <fa icon="bolt" v-if="charging "></fa>
        <fa icon="car-battery" v-if="!charging  "></fa>
        <h4>{{ charge }}%</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      charge: 88,
      charging: false,
    };
  },
  computed: {
    isEmpty() {
      if (this.charge < 25) {
        return true;
      } else {
        return false;
      }
    },
    range() {
      return (this.charge * 2.5).toFixed(0);
    },
  },
};
</script>

<style scoped>
#chargeWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#barLine {
  width: 100%;
  border: solid 1px white;
  border-radius: var(--radiusSmall);
  overflow: hidden;
}

#barWrapper {
  background-color: white;
  border-radius: 0 var(--radiusSmall) var(--radiusSmall) 0;
  /* width: 100%; */
  height: 40px;
  text-align: right;
  color: #2A2165;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  align-self: flex-start;
}

#rangeDiv {
  display: flex;
  align-items: baseline;
}

h3 {
  margin: 0 5px 10px 5px;
  font-weight: 800;
}

.empty {
  color: var(--red) !important;
}
</style>