<template>
  <div id="GeneralSettingsWrapper">
    <h2 class="wholeWidth">Account details</h2>
    <div id="settingsGrid" v-if="user">
      <div class="column">
        <h4>Algemeen</h4>
        <label>Gebruikersnaam</label>
        <input type="text" v-model="user.displayName" />

        <label>Email adres</label>
        <input type="text" v-model="user.email" />

        <label>Wachtwoord</label>
        <div class="buttonInput">
          <input type="text" placeholder="**************" />
          <the-base-button buttonStyle="danger" style="width: 150px"
            >Reset</the-base-button
          >
        </div>
      </div>
      <div class="column">
        <h4>Meldingen</h4>

        <label>Telefoonnummer</label>
        <input type="text" v-model="user.phoneNumber" disabled />
        <div class="toggleDiv">
          <label class="switch">
            <input type="checkbox" v-model="settings[0]" />
            <span class="slider round"></span>
          </label>
          <p>Nieuwe reservering</p>
        </div>

        <div class="toggleDiv">
          <label class="switch">
            <input type="checkbox" v-model="settings[1]" />
            <span class="slider round"></span>
          </label>
          <p>Lege batterij</p>
        </div>

        <div class="toggleDiv">
          <label class="switch">
            <input type="checkbox" v-model="settings[2]" />
            <span class="slider round"></span>
          </label>
          <p>Andere meldingen</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectAuth, projectDb } from "../firebaseConfig.js";
import TheBaseButton from "../components/UI/TheBaseButton.vue";

export default {
  components: { TheBaseButton },
  data() {
    return {
      user: null,
      settings: true,
    };
  },
  watch: {
    /* When user is loaded, loads the settings into the components */

    user() {
      var uid = this.user.uid;

      projectDb
        .collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            var docData = doc.data();
            this.settings = docData.settings;
          }
        });
    },
  },

  /* Loads the user data into the component */

  async mounted() {
    await projectAuth.onAuthStateChanged((user) => {
      this.user = user;
    });
  },
};
</script>

<style scoped>
#GeneralSettingsWrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
}

#settingsGrid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.column {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 650px;
}

label {
  margin: 40px 0 0 0;
  font-size: 13px;
}
input {
  width: 60%;
}

p {
  font-size: 13px;
}

.toggleDiv {
  display: flex;
  align-items: center;
  margin: 45px 0 0 0;
}

.buttonInput {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 20px 0 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>