<template>
  <div class="wall"></div>
  <div id="loginWrapper" @keydown.enter="loginUser()">
    <div id="mobileImg"></div>

    <div id="loginBody" v-motion-slide-top>
      <div id="header">
        <h2>Login</h2>
        <p class="subtitle">Vul hieronder je inloggevens in.</p>
      </div>
      <input
        name="username"
        type="text"
        placeholder="E-mailadres"
        v-model="loginCred.username"
        :class="{ wrongCredsInput: wrongCreds }"
      />
      <input
        name="password"
        type="password"
        placeholder="Wachtwoord"
        v-model="loginCred.password"
        :class="{ wrongCredsInput: wrongCreds }"
      />
      <div id="buttonDiv">
        <the-base-button @click="loginUser">Login</the-base-button>
        <div class="subtitle wrongCreds" v-if="wrongCreds">
          Onjuiste inloggevens, probeer het opnieuw.
        </div>

        <div class="subtitle wrongCreds" v-if="tooManyReq">
          Teveel mislukte pogingen, probeer later opnieuw
        </div>
        <p id="passReset">Wachtwoord vergeten?</p>
      </div>
    </div>
  </div>
</template>

<script>
import TheBaseButton from "../components/UI/TheBaseButton.vue";
import { projectAuth } from "../firebaseConfig.js";

export default {
  name: "login",
  data() {
    return {
      /* loginCred v-model'ed to login input */

      loginCred: {
        username: "",
        password: "",
      },

      wrongCreds: false,
      tooManyReq: false
    };
  },
  components: {
    TheBaseButton,
  },
  methods: {
    /* Logs in the user using the loginCred data */

    loginUser() {
      projectAuth
        .signInWithEmailAndPassword(
          this.loginCred.username,
          this.loginCred.password
        )
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          if (errorCode == "auth/too-many-requests") {
            this.tooManyReq = false;
            this.tooManyReq = true;
          }
          else{
            this.wrongCreds = true
          }
        });
    },
  },
};
</script>

<style scoped>
.wall {
  background-image: url("../assets/walls/loginWallFull.jpg");
  background-position: center;
  background-size: cover;
}

#loginWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#header {
  text-align: center;
  margin: 0 0 20px 0;
}

#mobileImg {
  display: none;
  margin: 0px;
  height: 100%;
  width: 100%;
  border-radius: var(--radius);
  background-color: aqua;
  background-image: url("../assets/walls/loginWallMobile.jpg");
  background-position: center;
  background-size: cover;
}

#loginBody {
  background-color: white;
  padding: 40px;
  box-sizing: border-box;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  box-shadow: var(--shadow);
}

#checkDiv {
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
}

#checkbox {
  height: 16px;
  width: 16px;
  border: none;
  background-color: var(--lightBlue);
  margin: 0 10px 0 0;
}

#buttonDiv {
  margin: 20px 0 0 0;
  width: 100%;
  text-align: center;
}

#passReset {
  font-size: 12px;
  margin: 20px 0 0 0;
  color: var(--blue);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

#passReset:hover {
  transform: scale(1.03);
}

.wrongCreds {
  margin: 15px 0 0 0;
  color: crimson;
  line-height: 18px;
}

.wrongCredsInput {
  border: solid 1px crimson;
}
/* 1440p */

@media only screen and (min-height: 1200px) {
  .wall {
    background-image: url("../assets/walls/loginWallLarge.jpg");
  }
}

/* tablet */

@media only screen and (max-height: 720px) {
  .wall {
    background-image: url("../assets/walls/loginWall720.jpg");
  }
}

/*Large mobile */

@media only screen and (min-width: 300px) and (max-width: 420px) {
  .wall {
    display: none;
  }

  #loginWrapper {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    box-sizing: border-box;
  }

  #mobileImg {
    display: block;
    max-height: 90vw;
    border-radius: 0px;
  }

  #loginBody {
    box-shadow: none;
    margin: 0;
    padding: 40px 40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>