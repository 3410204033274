<template>
  <transition name="modal">
    <the-message-creation-comp
      v-if="newMessageOpen"
      @closeModal="newMessageOpen = false"
      @reloadMessages="getMessages()"
    ></the-message-creation-comp>
  </transition>
  <div id="sidebarWrapper" :class="{ show: showSidebar }">
    <div class="addMessageButton" @click="newMessageOpen = true">
      <fa icon="plus"></fa>
    </div>
    <div id="sidebarContent">
      <the-aid-card
        v-if="false"
        v-motion-slide-left
        :delay="200"
      ></the-aid-card>
      <the-car-card
        v-if="false"
        v-motion-slide-left
        :delay="200"
      ></the-car-card>
      <the-legend-card v-motion-slide-left :delay="200"></the-legend-card>
      <the-info-card
        v-for="message in messages.slice(0, 2)"
        :messageData="message"
        :key="message.title"
        v-motion-slide-left
        :delay="200"
      ></the-info-card>
    </div>
  </div>
</template>

<script>
import TheAidCard from "./UI/TheAidCard.vue";
import TheCarCard from "./UI/TheCarCard.vue";
import TheLegendCard from "./UI/TheLegendCard.vue";
import TheInfoCard from "./UI/TheInfoCard.vue";

import { projectDb } from "../firebaseConfig.js";
import TheMessageCreationComp from "./UI/TheMessageCreationComp.vue";

export default {
  components: {
    TheAidCard,
    TheCarCard,
    TheLegendCard,
    TheInfoCard,
    TheMessageCreationComp,
  },
  props: ["showSidebar"],
  data() {
    return {
      messages: [],
      newMessageOpen: false,
    };
  },

  methods: {
    async getMessages() {
      this.messages = [];
      this.newMessageOpen = false;
      var messages = await projectDb.collection("messages");

      messages.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.messages.push(doc.data());
        });
      });
    },
  },

  mounted() {
    this.getMessages();
  },
};
</script>

<style scoped>
#sidebarWrapper {
  position: relative;
  height: auto;
  width: 600px;
  border-right: solid 1px #ddd;
  background-color: white;
  z-index: 3;
  overflow-y: hidden;
  box-sizing: border-box;
}

#sidebarContent {
  grid-template-columns: 1fr;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-items: flex-end;
  align-content: flex-start;
  overflow: scroll;
}

.cardWrapper {
  position: relative;

  width: 100%;
  max-width: 420px;
  max-height: 300px;
  line-height: 22px;
  background: white;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  border: 1px #ddd solid;
  padding: 40px;
  box-sizing: border-box;
  color: white;
}

.addMessageButton {
  position: absolute;
  bottom: 30px;
  right: 30px;
  border: 2px solid var(--blue);
  padding: 12px 12px;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: var(--blue);
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.addMessageButton:hover {
  transform: scale(1.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.modal-enter-to,
.modal-leave-from {
  transform: scale(1);
  opacity: 1;
}

.modal-enter-from,
.modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.2s ease;
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  #sidebarWrapper {
    width: 580px;
  }
}

@media screen and (min-width: 0px) and (max-width: 1600px) {
  #sidebarWrapper {
    width: 500px;
    position: absolute;
    transform: translatex(-100%);
    transition: all 0.2s ease-in-out;
    box-shadow: var(--shadow);
  }

  .show {
    transform: translateX(0px) !important;
  }
}
</style>