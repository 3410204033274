<template>
  <div class="cardWrapper">
    <h3>Legenda</h3>
    <div id="legendWrapper">
      <div class="legendItem">
        <div class="legendColor"></div>
        <h5 v-if="user">Olivier Messelink <b v-if="user.uid == '0tjgEBE5cLgM8Av4NG6hJbnW1DC3'"> (Jij)</b></h5>
      </div>

      <div class="legendItem">
        <div class="legendColor" style="backgroundColor: var(--red)"></div>
        <h5 v-if="user">Resi Messelink<b v-if="user.uid == 'M9qcllcWbQab265TX6Jle4jazHo2'"> (Jij)</b></h5>
      </div>

      <div class="legendItem">
        <div class="legendColor" style="backgroundColor: var(--green)"></div>
        <h5 v-if="user">Lia Kooremans<b v-if="user.uid == 'tcxEBYkO7qZwAWwG6f4Gc1rkwuH2'"> (Jij)</b></h5> 
      </div>
    </div>
  </div>
</template>

<script>
import { projectAuth } from "../../firebaseConfig.js";

export default {
  data() {  
    return {
      user: null,
    };
  },

  async mounted() {
    await projectAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      }
    });
    return null;
  },
};
</script>

<style scoped>
.cardWrapper {
  background: white;
  border: 1px #ddd solid;
  color: black !important;
}

h3 {
  color: black;
  margin: 0 0 30px 0;
}

.legendItem {
  display: flex;
  flex-direction: row;
  margin: 15px 0 0 0;
}

.legendColor {
  height: 16px;
  width: 16px;
  background-color: var(--blue);
  margin: 0 10px 0 0;
  border-radius: 6px;
}
</style>