<template>
  <div id="messagesWrapper">
    <div id="floatingButton" @click="newMessageOpen = true">
      <fa icon="plus"></fa>
    </div>
    <transition name="modal">
      <the-message-creation-comp
        v-if="newMessageOpen"
        @closeModal="newMessageOpen = false"
        @reloadMessages="getMessages()"
      ></the-message-creation-comp>
    </transition>
    <the-info-card
      v-for="(message, i) in messages.slice().reverse()"
      :messageData="message"
      :title="message"
      :key="message.title"
      v-motion-slide-left
      :delay="100 * i"
    ></the-info-card>
  </div>
</template>

<script>
import { projectDb } from "../firebaseConfig.js";
import TheInfoCard from "./UI/TheInfoCard.vue";
import TheMessageCreationComp from "./UI/TheMessageCreationComp.vue";

export default {
  components: { TheInfoCard, TheMessageCreationComp },
  data() {
    return {
      messages: [],
      newMessageOpen: false,
    };
  },
  methods: {
    async getMessages() {
      var messages = await projectDb.collection("messages");
      this.messages = [];
      this.newMessageOpen = false;
      messages.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.messages.push(doc.data());
        });
      });
    },
  },

  mounted() {
    this.getMessages();
  },
};
</script>

<style scoped>
#messagesWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  gap: 15px;
  padding: 10px;
}

.cardWrapper {
  position: relative;

  width: 100%;
  max-width: 420px;
  max-height: 300px;
  line-height: 22px;
  background: white;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  border: 1px #ddd solid;
  padding: 40px;
  box-sizing: border-box;
  color: white;
}

#floatingButton {
  position: absolute;
  bottom: 30px;
  right: 30px;
  border: 2px solid var(--blue);
  padding: 12px 12px;
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: var(--blue);
  color: white;
  font-size: 18px;
}

.modal-enter-to,
.modal-leave-from {
  transform: scale(1);
  opacity: 1;
}

.modal-enter-from,
.modal-leave-to {
  transform: scale(1.1);
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.2s ease;
}
</style>