<template>
  <div id="mobileMenuWrapper">
    <div id="menuHeader">
      <h2>Menu</h2>
      <div class="ico closeButton" @click="$emit('closeMenu')">
        <fa icon="times"></fa>
      </div>
    </div>
    <div id="menuList">
      <div
        class="navItem"
        :class="{ active: $route.path == '/home' }"
        @click="
          $router.push('/home');
          $emit('closeMenu');
        "
      >
        <fa
          class="ico"
          icon="calendar-days"
          :class="{ active: $route.path == '/home' }"
        ></fa>
        <h4>Agenda</h4>
      </div>

      <div
        class="navItem"
        :class="{ active: $route.params.nav == 'messagesMobile' }"
        @click="
          $router.push('/messagesMobile');
          $emit('closeMenu');
        "
      >
        <div id="counterWrapper">
          <fa class="ico" icon="info-circle"></fa>
          <p id="counter" v-if="messageCounter">{{ messageCounter }}</p>
        </div>
        <h4>Berichten</h4>
      </div>

      <div
        class="navItem"
        v-if="false"
        :class="{ active: $route.params.nav == 'settings' }"
        @click="
          $router.push('/settings/account');
          $emit('closeMenu');
        "
      >
        <fa class="ico" icon="gear"></fa>
        <h4>Instellingen</h4>
      </div>
      <div class="navItem logOut" @click="logOut()">
        <fa class="ico logOut" icon="sign-out-alt"></fa>
        <h4>Uitloggen</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { projectAuth, projectDb } from "../../firebaseConfig.js";

export default {
  emits: ["closeMenu"],
  data() {
    return {
      messageCounter: 0,
    };
  },
  methods: {
    logOut() {
      projectAuth.signOut().then(this.$router.push("/login"));
    },

    async getMessagesLength() {
      var messages = await projectDb.collection("messages");

      messages.get().then((querySnapshot) => {
        querySnapshot.forEach(() => {
          this.messageCounter++;
        });
      });
    },
  },
  mounted() {
    this.getMessagesLength();
  },
};
</script>

<style scoped>
#mobileMenuWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 99;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#menuHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#menuList {
  width: 100%;
  margin: 40px 0 0 0;
}

.ico {
  font-size: 26px;
  padding: 10px;
}

h2 {
  color: black;
  margin: 0px;
}

h4 {
  font-size: 18px;
}

.ico {
  margin: 0 15px 0 0;
}

.closeButton {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--blue);
  border-radius: 10000px;
  color: white;
  font-size: 18px;
}

.navItem {
  width: 100%;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 40px 0;
}

.active {
  color: var(--blue) !important;
}

.logOut {
  color: var(--red);
}

#counterWrapper {
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#counter {
  position: absolute;
  top: 4px;
  right: 20px;
  background-color: var(--red);
  padding: 2px;
  width: 12px;
  height: 12px;
  border-radius: 10000px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 11px;
  font-weight: bold;
}
</style>
