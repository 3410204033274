<template>
  <div id="GeneralSettingsWrapper">
    <h2 class="wholeWidth">Algemene instellingen</h2>
    <div id="settingsGrid">
      <div id="general">
        <h4>Algemeen</h4>
      </div>
      <div id="general">
        <h4>Andere</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#GeneralSettingsWrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
}

#settingsGrid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
}

#general {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
}
</style>