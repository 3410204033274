<template>
  <transition name="fade">
    <the-reservation-comp
      v-if="startDate"
      :startDate="startDate"
      :user="user"
      @close="startDate = null"
      @reload="loadEvents()"
    ></the-reservation-comp>
  </transition>
  <transition name="fade">
    <the-delete-reservation-comp
      v-if="deleteDate"
      @close="deleteDate = null"
      @reload="loadEvents()"
      :deleteEvent="deleteDate"
    ></the-delete-reservation-comp>
  </transition>
  <div
    id="calendarWrapper"
    tabindex="0"
    @keydown.esc="startDate = null"
    v-touch:swipe="swipeHandler"
  >
    <the-sidebar :showSidebar="openSidebar"></the-sidebar>

    <div id="calContentWrapper">
      <the-cal-bar
        :date="activeView"
        @click="showSidebar = !showSidebar"
        @back="$refs.vuecal.previous()"
        @next="$refs.vuecal.next()"
        @now="$refs.vuecal.switchView('day', new Date())"
      ></the-cal-bar>
      <vue-cal
        ref="vuecal"
        class="vuecal--blue-theme"
        :events="events"
        :selected-date="showDate"
        :time="false"
        events-count-on-year-view="events-count-on-year-view"
        events-on-month-view="short"
        active-view="month"
        :disable-views="['years', 'year', 'day', 'week']"
        hide-view-selector
        hide-title-bar
        :cell-click-hold="false"
        :drag-to-create-event="false"
        editable-events
        @cell-click="openDatePicker($event)"
        @event-click="openDeleteMode($event)"
        @view-change="activeView = $event.endDate"
      >
      </vue-cal>
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { projectAuth, projectDb } from "../firebaseConfig";

import TheSidebar from "./TheSidebar.vue";
import TheReservationComp from "./UI/TheReservationComp.vue";
import TheDeleteReservationComp from "./UI/TheDeleteReservationComp.vue";
import TheCalBar from "./UI/TheCalBar.vue";

export default {
  components: {
    VueCal,
    TheSidebar,
    TheReservationComp,
    TheDeleteReservationComp,
    TheCalBar,
  },
  props: ["openSidebar"],
  emits: ["activeView"],
  data() {
    return {
      showDate: new Date(),
      user: null,
      startDate: null,
      events: [],
      deleteDate: null,
      activeView: new Date(),
      showSidebar: false,
    };
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
    openDatePicker(event) {
      var date = event;
      this.startDate = date;
    },
    openDeleteMode(event) {
      this.deleteDate = event;
    },
    loadEvents() {
      projectDb
        .collection("calendar-data")
        .doc("reservations")
        .get()
        .then((doc) => {
          if (doc.exists) {
            var docData = doc.data();
            this.events = docData.events;
          }
        });
    },
    swipeHandler(evt) {
      if (evt === "left") {
        this.$refs.vuecal.next();
      }
      if (evt === "right") {
        this.$refs.vuecal.previous();
      }
    },
  },
  watch: {
    activeView() {
      this.$emit("activeView", this.activeView);
    },
  },

  async mounted() {
    await projectAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.loadEvents();
      }
    });
    return null;
  },
};
</script>

<style scoped>
#calendarWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.vuecal--blue-theme {
  padding: 30px;
  box-sizing: border-box;
}

#calContentWrapper {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .vuecal--blue-theme {
    padding: 0px;
  }
}
</style>